import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppAuthorized from './AppAuthorized'
import AppUnauthorized from './AppUnauthorized'
import AppFailed from './AppFailed'
import * as serviceWorker from './serviceWorker'

import Api from './utils/Api'

Api.fetchAuth('/me').then(response=>{
  // console.log('resp', response)
  response.json().then(res=>{
    initApp(<AppAuthorized me={res.data} />)
  })
}).catch(error=>{
  if ( error.status === 401 ) {
    initApp(<AppUnauthorized />)
  } else {
    initApp(<AppFailed error={error} />)
  }
})

const initApp = (app)=>{
  ReactDOM.render(app, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
