import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
// import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import newTextField from 'components/molecules/forms/newTextField'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
// import newKeyValueFields from 'components/molecules/forms/newKeyValueFields'
// import newNestedKeyValueFields from 'components/molecules/forms/newNestedKeyValueFields'
import newCodeEditor from 'components/molecules/forms/newCodeEditor'

import HelpTip from 'components/atoms/HelpTip'

export default ({save, prefix, subject}) => {
  subject = subject || {}
  const path = newTextField({
    default: subject.path || "", 
    label: "Path", 
    fullWidth: true, disabled: !!subject.path,
    InputProps: prefix ?
      {startAdornment: <InputAdornment position="start">{`${prefix}/`}</InputAdornment>} :
      null
  })
  const name = newTextField({default: subject.name || "", label: "Name", fullWidth: true})
  const image = newSelectableAssets({
    default: Array.of(subject.image).filter(x=>x),
    max: 1,
    baseQuery: {
      kind: 'label',
      // content_type: 'image',
    }
  })
  const body = {
    path: prefix ? `${prefix}/${path.value}` : path.value,
    name: name.value,
    image_id: image.hasValues() ? image.values[0].id : null,
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      (subject.path ? path : [path, (
        <HelpTip>
          ラベルを識別するユニークな文字列です。<br/>
          スラッシュ区切りで階層構造を設けられます。アルファベット・数字・ハイフンが使用できます。<br/>
          文字数が少ないほど検索効率が高まるので、なるべく短い名前を推奨します。<br/>
          設定例: news, /b/cat/pickup
        </HelpTip>
      )]),
      name, image,
    ]} />
  )
}