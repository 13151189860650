import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell'
// import PriceTag from 'components/atoms/icons/PriceTag'
// import {format} from 'date-fns'

export default ({
  id, slug, title, full_title, issue, status
}) => (
  <React.Fragment>
    <TableCell>
      <Link href={`/articles/${id}`} color="inherit">
        <Typography variant="body2">{slug}</Typography>
      </Link>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{full_title}</Typography>
    </TableCell>
    <TableCell>
      {issue && <Chip size="small" label={issue.title} />}
    </TableCell>
    <TableCell>
      <Chip size="small" label={status} color={status==='opened' ? 'primary' : 'default'} />
    </TableCell>
  </React.Fragment>
)