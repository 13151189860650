import React, {useState,useEffect} from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CheckIcon from '@material-ui/icons/Check';
import Modal80 from 'components/atoms/Modal80'
import newDraggableList from 'components/molecules/forms/newDraggableList'


import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';

export default (props) => {
  const [opened, setOpened] = useState(false)
  const openSortModal = () => setOpened(true)
  const closeSortModal = () => setOpened(false)

  return (
    <React.Fragment>
      <Modal80 open={opened} onClose={()=>closeSortModal()}>
        {opened && <SortModalInner {...props} handleClose={closeSortModal} />}
      </Modal80>
      <IconButton size="small" color="default" 
        onClick={()=>openSortModal()}>
        <SwapVertIcon />
      </IconButton>
    </React.Fragment>
  )
}


const SortModalInner = ({values, setValues, handleClose, sortProps}) => {
  const draggableList = newDraggableList({
    ...sortProps,
    defaultValue: values,
  })
  const handleClick = () => {
    setValues(draggableList.items)
    handleClose()
  }
  return (
    <Box p={2}>
      <Grid container spacing={2} direction="column">
        <Grid item>{draggableList.render}</Grid>
        <Grid item>
          <Button variant="contained" color="primary"
            startIcon={<CheckIcon />}
            onClick={handleClick}>OK</Button>
        </Grid>
      </Grid>
    </Box>
  )
}