import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newSelect from 'components/molecules/forms/newSelect'
import newDateTime from 'components/molecules/forms/newDateTime'
import newSelectablePdfAssets from 'components/molecules/forms/newSelectablePdfAssets'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableModalList from 'components/molecules/forms/newSelectableModalList'
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

import ArchiveSeriesListItem from 'components/pages/ArchiveSeries/parts/ListItem'

export default ({save, subject}) => {
  const classes = useStyles()
  const {handleApiError} = useFlash()
  subject = subject || {}

  const slug = newTextField({
    defaultValue: subject.slug || null, 
    label: "Slug", fullWidth: true,
    disabled: true,
  })
  const title = newTextField({
    defaultValue: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const order = newTextField({
    defaultValue: subject.order || 0, 
    label: "表示順",
    type: 'number',
  })
  const content = newHtmlEditor({
    defaultValue: subject.content || "", label: "本文html",
    assetsProps: {baseQuery: {kind: 'archive'}}
  })

  const seriesSelector = newSelectableModalList({
    label: "シリーズ",
    max: 1, 
    defaultValue:  subject.archive_series,
    modalListProps: {
      headers: ['', 'slug', 'タイトル', '公開日時', 'ステータス'],
      ListItemComponent: (props) => (<ArchiveSeriesListItem {...props} />),
      onPage: ({offset, limit, updateRows}) => {
        const params = {
          query: {offset, limit}
        }
        Api.fetchAuth(`/archive_series`, params).then(r=>r.json()).then(response=>{
          updateRows(response.data, response.total)
        }).catch(handleApiError)
      },
    }
  })

  const image = newSelectableAssets({
    default: subject.image ? [subject.image] : [],
    max: 1,
    baseQuery: {kind: 'archive'}
  })

  const file = newSelectablePdfAssets({
    default: subject.file ? [subject.file] : [],
    max: 1,
    baseQuery: {kind: 'archive_pdf'}
  })
  const pdf_orientation = newSelect({
    defaultValue: subject.pdf_orientation || 'ltr' , 
    options: {'ltr': "左綴じ", 'rtl': "右綴じ"},
    label: "pdf orientation", required: true,
  })


  const body = {
    slug: slug.value,
    title: title.value,
    content: content.html,
    image_id: image.values[0] && image.values[0].id,
    archive_series_slug: seriesSelector.values[0] && seriesSelector.values[0].slug,
    blob_id: file.values[0] && file.values[0].id,
    pdf_orientation: pdf_orientation.value,
    order: order.value,
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug, title, order, seriesSelector, content, image,file , pdf_orientation
    ]} />
  )
}

const useStyles = makeStyles(theme=>({
  editor: {
    '&>.CodeMirror': {height: '180px'},
  }
}))
