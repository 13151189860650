import React, {useState} from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
// import Grid from '@material-ui/core/Grid';
// import newTextField from 'components/molecules/forms/newTextField'

import newCodeEditor from 'components/molecules/forms/newCodeEditor'

export default ({save, subject}) => {
  const data = newCodeEditor({
    defaultValue: subject.data,
    options: {
      mode: subject.content_type,
    },
  })

  const body = {
    data: data.value,
  }

  return (
    <GeneratedForm handleSave={()=>save(body, subject)} objects={[
      data,
      (<div>{JSON.stringify(data.values)}</div>),
    ]} />
  )
}
