import {useState,useEffect} from 'react'
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

export default ({baseQuery}) => {
  const [loading, setLoading] = useState(false)
  const [assets, setAssets] = useState([])
  const query = baseQuery || {}

  const {handleApiError, enqueuePermanentError} = useFlash()

  useEffect(()=>{
    setLoading(true)
    Api.fetchAuth('/assets', {query}).then(r=>r.json()).then(res=>{
      setAssets(res.data)
      setLoading(false)
    }).catch(error=>{
      // console.log('abc err',error)
      setLoading(false)
      handleApiError(error)
    })
  }, [])

  const onDrop = (files,extra) => {
    console.log('files', files, extra)

    files.forEach(file=>{

      if (! file.type.match('pdf.*')) throw Error("Please upload pdf.")

        const formData = new FormData()
        formData.append("file", file)
        Object.keys(query).forEach(k=>{
          formData.append(k, query[k])
        })
        Api.fetchAuth('/assets/upload', {
          method: 'POST',
          body: formData
        }).then(r=>r.json()).then(response=>{
          // onUploaded(response.data)
          setAssets(_state=>[response.data, ..._state])
        }).catch(handleApiError)
    })
  }

  return {
    loading, assets, setAssets,
    onDrop
  }
}
