import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell'
// import PriceTag from 'components/atoms/icons/PriceTag'
import {format} from 'date-fns'

export default ({
  id, slug, title, archive_series, order, status
}) => (
  <React.Fragment>
    <TableCell>
      <Link href={`/archives/${id}`} color="inherit">
        <Typography variant="body2">{slug}</Typography>
      </Link>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{title}</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{ archive_series ? archive_series.title : '' }</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{ order }</Typography>
    </TableCell>
    <TableCell>
      <Chip size="small" label={status} color={status==='opened' ? 'primary' : 'default'} />
    </TableCell>
  </React.Fragment>
)