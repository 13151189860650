import React, {useState} from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import Chip from '@material-ui/core/Chip';
import newTextField from 'components/molecules/forms/newTextField'

import newSelectableModalForm from 'components/molecules/forms/newSelectableModalForm'

export default ({save, subject}) => {
  subject = subject || {}
  
  const data = newSelectableModalForm({
    defaultValue: subject.data,
    max: 3, label: 'リンク',
    modalProps: {
      form: ItemForm({}),
      defaultValue: {},
    },
    ListItemComponent: ListItem,
    sortProps: {
      Component: ListItem,
    },
  })

  const body = {
    data: data.values,
  }

  return (
    <GeneratedForm handleSave={()=>save(body, subject)} objects={[
      data,
      // (<div>{JSON.stringify(data.values)}</div>),
    ]} />
  )
}

const ItemForm = ({value})=>{
  value = value || {}
  const title = newTextField({
    defaultValue: value.title,
    label: 'タイトル', fullWidth: true,
  })
  const url = newTextField({
    defaultValue: value.url,
    label: 'URL', fullWidth: true,
  })
  const reset = ()=>{
    title.reset()
    url.reset()
  }
  const render = (
    <Grid container spacing={1} direction="column">
     <Grid item>{title.render}</Grid>
     <Grid item>{url.render}</Grid>
   </Grid>
  )
  return {
    render, reset,
    value: {
      title: title.value,
      url: url.value,
    },
  }
}

const ListItem = ({title, url})=>(
  <React.Fragment>
    <TableCell>
      <Typography variant="body2">{title}</Typography>
    </TableCell>
    <TableCell>
      <Link href={`${url}`} color="inherit">
        <Typography variant="body2">{url}</Typography>
      </Link>
    </TableCell>
  </React.Fragment>
)
