import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'

export default ({save, subject}) => {
  subject = subject || {}

  const slug = newTextField({
    default: subject.slug || "", 
    label: "Slug", fullWidth: true, disabled: !!subject.id,
  })
  const name = newTextField({
    default: subject.name || "", 
    label: "名前", fullWidth: true, required: true,
  })
  const name_ruby = newTextField({
    default: subject.name_ruby || "", 
    label: "名前（ルビ）", fullWidth: true,
  })
  const description = newHtmlEditor({
    defaultValue: subject.description || "", label: "",
    assetsProps: {baseQuery: {kind: 'author'}}
  })
  const image = newSelectableAssets({
    default: subject.image ? [subject.image] : [],
    max: 1,
    baseQuery: {kind: 'author'}
  })
  const image_description = newTextField({
    default: subject.image_description || "",
    label: "画像クレジット", fullWidth: true,
  })
  const order_priority = newTextField({
    default: subject.order_priority || "",
    label: "著者上位表示の優先度（降順）", fullWidth: true,
  })

  const body = {
    slug: slug.value,
    name: name.value,
    name_ruby: name_ruby.value,
    description: description.html,
    image_id: image.values.map(x=>x.id)[0],
    image_description: image_description.value,
    order_priority: order_priority.value
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug, name, 
      name_ruby,
      description,
      image,
      image_description,
      order_priority
    ]} />
  )
}