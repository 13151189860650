import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'

import Form from './parts/Form'

export default (props) => {
  const series_id = props.match.params.series_id
  const [series, setSeries] = useState()
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setLoading(true)
    
    const api1 = Api.fetchAuth(`/series/${series_id}`).then(r=>r.json())
    Promise.all([api1]).then(responses=>{
      setSeries(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      setLoading(false)
      handleApiError(error)
    })
  }, [])

  const save = (body) => {
    console.log('body', body)
    return Api.fetchAuth('/articles', {
      method: 'POST',
      body: body
    }).then(r=>r.json()).then(response=>{
      window.location.href = '/articles'
    }).catch((error)=>{
      handleApiError(error)
    })
  }

  if (loading) return <CircularProgress />

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>コンテンツ新規作成</Typography>
      <Form {...{save, series}} />
    </WrapForm>
  )
}
