import React, {useState} from 'react'
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { Wrapper } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'
// import ButtonUpload from 'components/atoms/ButtonUpload'
import ResourceStatusButton from 'components/atoms/ResourceStatusButton'
import ListItem from './parts/ListItem'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup'
import newTextField from 'components/molecules/forms/newTextField'
import Button from '@material-ui/core/Button'

export default (props) => {
  const {enqueueSnackbar, handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState({})

  const itemPager = newRowsPager({
    headers: ['slug', 'タイトル', '刊行', 'ステータス', 'action'],
    baseQuery: searchQuery,
    onPage: ({offset, limit, baseQuery, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {...baseQuery, offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/articles`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <ListItem {...row} />
        <TableCell>
          <ResourceStatusButton 
            resource_key="articles" 
            resource={row} 
            updateRowById={updateRowById} />
        </TableCell>
      </React.Fragment>
    )
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            コンテンツ一覧
          </Grid>
        </Grid>
      </Typography>

      <IndexSearchForm setSearchQuery={setSearchQuery} enqueueSnackbar={enqueueSnackbar}/>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}

const IndexSearchForm = ({setSearchQuery, enqueueSnackbar}) => {
  const keyword = newTextField({
    label: "キーワードで検索できます",
    fullWidth: true,
  })
  const search = () => {
    setSearchQuery({keyword: keyword.value})
  }
  const reset = () => {
    keyword.reset()
    setSearchQuery({})
  }
  const classes = useStyles()
  //console.log(itemPager)

  return (
    <Paper className={classes.paper}>
      <Grid container direction="row" spacing={4}>
        <Grid item container alignItems="center" md={12} spacing={2}>
          <Grid item xs={12}>
            {keyword.render}
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <Button
                onClick={()=>search()}
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                startIcon={<SearchIcon />}>
                Search
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={()=>reset()}
                variant="contained"
                color="default"
                fullWidth
                size="small"
                startIcon={<RestoreIcon />}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));