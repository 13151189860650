import React, {useState, useMemo} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CreditCardIcon from '@material-ui/icons/CreditCard'
import CheckIcon from '@material-ui/icons/Check';
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { Wrapper } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'

import {OrderPaymentState, OrderPaymentMethod} from 'components/atoms/icons/OrderIcon'
import CustomerName from 'components/atoms/CustomerName'

import {format, formatDistance, startOfMonth, endOfMonth} from 'date-fns'

import newDateTime from 'components/molecules/forms/newDateTime'
import newDateTimeRange from 'components/molecules/forms/newDateTimeRange'
import newTextField from 'components/molecules/forms/newTextField'
// import useIncrementer from 'modules/useIncrementer'

export default (props) => {
  const [searchQuery, setSearchQuery] = useState({
    payment_states: ["pending", "completed"],
    payment_methods: ["card", "amazonpay", "special"],
    start_at: startOfMonth(new Date),
    end_at: endOfMonth(new Date),
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>支払い一覧</Grid>
        </Grid>
      </Typography>
      
      {/*<IndexOperationForm />*/}
      <Link href="/orders/card_payment_operation">カード月次オペレーション</Link>
      <IndexSearchForm setSearchQuery={setSearchQuery} />
      <IndexInner searchQuery={searchQuery} />
    </Wrapper>
  )
}

const IndexSearchForm = ({setSearchQuery}) => {
  const dateRange = newDateTimeRange({
    startAtProps: {defaultValue: startOfMonth(new Date)},
    endAtProps: {defaultValue: endOfMonth(new Date)},
  })
  
  const paymentStates = ["pending", "completed"]
  const paymentMethods = ["card", "amazonpay", "special"]
  
  const [searchPaymentStates, setSearchPaymentStates] = useState(paymentStates)
  const [searchPaymentMethods, setSearchPaymentMethods] = useState(paymentMethods)
  
  const changePaymentStatusQuery = (value) => {
    const values = searchPaymentStates.includes(value) ? 
      searchPaymentStates.filter(a=>a!==value) :
      searchPaymentStates.concat([value])
    setSearchPaymentStates(values)
  }
  const changePaymentMethodQuery = (value) => {
    const values = searchPaymentMethods.includes(value) ? 
      searchPaymentMethods.filter(a=>a!==value) :
      searchPaymentMethods.concat([value])
    setSearchPaymentMethods(values)
  }
  const reset = () => {
    dateRange.reset()
    // freeKeyword.reset()
    setSearchPaymentStates(paymentStates)
  }
  const search = () => {
    setSearchQuery({
      payment_states: searchPaymentStates,
      payment_methods: searchPaymentMethods,
      start_at: dateRange.startAt.value,
      end_at: dateRange.endAt.value,
      // keyword: freeKeyword.value,
    })
  }
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Chip size="small" label="絞り込み" color="primary" />
      <Grid container direction="row" spacing={2}>
        <Grid item md={12}>
          <FormGroup row>{dateRange.render}</FormGroup>
          {/*<FormGroup row>{freeKeyword.render}</FormGroup>*/}
          <Grid item container alignItems="center">
            <Grid item container md={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item><CreditCardIcon /></Grid>
                <Grid item>支払いステータス</Grid>
              </Grid>
            </Grid>
            <Grid item md={10}>
              <FormGroup row>
              {paymentStates.map(x=>{
                return <FormControlLabel key={x} label={x} control={
                  <Checkbox 
                    color="primary"
                    checked={searchPaymentStates.includes(x)} 
                    onChange={()=>changePaymentStatusQuery(x)} />
                } />
              })}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item container md={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item><CreditCardIcon /></Grid>
                <Grid item>支払い方法</Grid>
              </Grid>
            </Grid>
            <Grid item md={10}>
              <FormGroup row>
              {paymentMethods.map(x=>{
                return <FormControlLabel key={x} label={x} control={
                  <Checkbox 
                    color="primary"
                    checked={searchPaymentMethods.includes(x)} 
                    onChange={()=>changePaymentMethodQuery(x)} />
                } />
              })}
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs spacing={1}>
          <Grid item>
            <Button
              onClick={()=>search()}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={()=>reset()}
              variant="contained"
              color="default"
              size="small"
              startIcon={<RestoreIcon />}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const IndexInner = (props) => {
  const classes = useStyles()
  const jpyFormat = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  })
  const {handleApiError} = useFlash()
  const {searchQuery} = props
  const [loading, setLoading] = useState(true)
  const itemPager = newRowsPager({
    headers: ['id', '発行月', 'ユーザ', '詳細', '支払い'],
    baseQuery: searchQuery,
    onPage: ({offset, limit, baseQuery, updateRows}) => {
      const params = {
        query: {...baseQuery, offset, limit}
      }
      console.log('params', params)
      setLoading(true)
      Api.fetchAuth(`/orders`, params).then(r=>r.json()).then(response=>{
        const {data, total, ...rest} = response
        updateRows(data, total, rest)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row}) => {
      return (
        <React.Fragment>
          <TableCell>
            <Link href={`/orders/${row.id}`}>
              {row.id}
            </Link>
          </TableCell>
          <TableCell>
            {format(new Date(row.issue_date), "yyyy/MM")}
          </TableCell>
          <TableCell>
            {row.user && (<CustomerName userId={row.user.id} full_name={row.user.full_name} />)}
          </TableCell>
          <TableCell>
            <Grid container>
              <Grid item container>
                <Grid item xs={2}>price</Grid><Grid item xs={10}><Typography align="right">{jpyFormat.format(row.total_price)}</Typography></Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={2}>tax</Grid><Grid item xs={10}><Typography align="right">{jpyFormat.format(row.total_tax)}</Typography></Grid>
              </Grid>
              <Grid item><Divider /></Grid>
              <Grid item container>
                <Grid item xs={2}>total</Grid><Grid item xs={10}><Typography align="right">{jpyFormat.format(row.total_payment)}</Typography></Grid>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <OrderPaymentState order={row} />
                {/*<OrderStatus order={row} />*/}
              </Grid>
              <Grid item>
                <OrderPaymentMethod order={row} />
              </Grid>
            </Grid>
          </TableCell>
        </React.Fragment>
      )
    }
  })

  return (
    <React.Fragment>
      {loading && <CircularProgress size={20} />}
      {itemPager.info && (
        <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
          <Typography variant="subtitle1" align="right">売上: {jpyFormat.format(itemPager.info.sales)}</Typography>
        </Box>
      )}
      {itemPager.render}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));