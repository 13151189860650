import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell'
// import PriceTag from 'components/atoms/icons/PriceTag'
import {format} from 'date-fns'
import Check from 'components/atoms/icons/Check'
import { lime } from '@material-ui/core/colors'

export default ({
  id, slug, title, start_at, status
}) => (
  <React.Fragment>
    <TableCell>
      <Link href={`/issues/${id}`} color="inherit">
        <Typography variant="body2">{slug}</Typography>
      </Link>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{title}</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body2">
        {format(new Date(start_at), "yyyy/MM/dd HH:mm:ss")}{' '}
        {(new Date() > new Date(start_at)) && <Check style={{fontSize: 14, color: lime[500]}} />}
        </Typography>
    </TableCell>
    <TableCell>
      <Chip size="small" label={status} color={status==='opened' ? 'primary' : 'default'} />
    </TableCell>
  </React.Fragment>
)