import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { Wrapper } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'
import {CustomerIcon} from 'components/atoms/CustomerName'

import {format, formatDistance} from 'date-fns'

import CreditCardIcon from '@material-ui/icons/CreditCard'
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import newTextField from 'components/molecules/forms/newTextField'
import ButtonDownload from 'components/atoms/ButtonDownload'

export default (props) => {
  const [searchQuery, setSearchQuery] = useState({})
  const {handleApiError} = useFlash()
  const handleDownload = () => {
    Api.fetchAuth(`/subscriptions/download`).then(r=>r.blob()).then(res=>{
      // console.log('res', res)
      const uri = URL.createObjectURL(res)
      const link = document.createElement("a")
      // link.download = 'data.zip'
      link.href = uri
      link.click()
    }).catch(handleApiError)
  }
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>List of Subscriptions</Grid>
          <Grid item>
            <ButtonDownload 
              onClick={handleDownload}/>
          </Grid>
        </Grid>
      </Typography>
      
      <IndexSearchForm setSearchQuery={setSearchQuery} />
      <IndexInner searchQuery={searchQuery} />
    </Wrapper>
  )
}

const IndexSearchForm = ({setSearchQuery}) => {
  // const emailKeyword = newTextField({
  //   label: "Search by customer email.",
  //   fullWidth: true,
  // })
  // const nameKeyword = newTextField({
  //   label: "Search by customer name.",
  //   fullWidth: true,
  // })
  // const statuses = ["active", "temporary"]
  // const paymentStates = ["active", "inactive"]
  // const [searchStatuses, setSearchStatuses] = useState(statuses)
  // const [searchPaymentStates, setSearchPaymentStates] = useState(paymentStates)
  // const changeStatusQuery = (value) => {
  //   const values = searchStatuses.includes(value) ?
  //     searchStatuses.filter(a=>a!==value) :
  //     searchStatuses.concat([value])
  //   setSearchStatuses(values)
  // }
  // const changePaymentStatusQuery = (value) => {
  //   const values = searchPaymentStates.includes(value) ? 
  //     searchPaymentStates.filter(a=>a!==value) :
  //     searchPaymentStates.concat([value])
  //   setSearchPaymentStates(values)
  // }
  // const updateSearchQuery = (query) => {
  //   const defaultQuery = {
  //     statuses: searchStatuses,
  //     payment_states: searchPaymentStates,
  //     email: emailKeyword.value,
  //     name: nameKeyword.value,
  //   }
  //   setSearchQuery({...defaultQuery, ...query})
  // }
  // const reset = () => {
  //   emailKeyword.reset()
  //   nameKeyword.reset()
  //   setSearchStatuses(statuses)
  //   setSearchPaymentStates(paymentStates)
  // }
  // const search = () => {
  //   setSearchQuery({
  //     statuses: searchStatuses,
  //     payment_states: searchPaymentStates,
  //     email: emailKeyword.value,
  //     name: nameKeyword.value,
  //   })
  // }
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      {/*<Grid container direction="row" spacing={2}>
        <Grid item md={8}>
          <FormGroup row>{emailKeyword.render}</FormGroup>
          <FormGroup row>{nameKeyword.render}</FormGroup>
        </Grid>
        <Grid item container alignItems="center" md={4}>
          <Grid item md={2}>Status</Grid>
          <Grid item md={10}>
            <FormGroup row>
            {statuses.map(x=>{
              return <FormControlLabel key={x} label={x} control={
                <Checkbox 
                  color="primary"
                  checked={searchStatuses.includes(x)} 
                  onChange={()=>changeStatusQuery(x)} />
              } />
            })}
            </FormGroup>
          </Grid>

          <Grid item md={2}><CreditCardIcon /></Grid>
          <Grid item md={10}>
            <FormGroup row>
            {paymentStates.map(x=>{
              return <FormControlLabel key={x} label={x} control={
                <Checkbox 
                  color="primary"
                  checked={searchPaymentStates.includes(x)} 
                  onChange={()=>changePaymentStatusQuery(x)} />
              } />
            })}
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item container xs spacing={1}>
          <Grid item>
            <Button
              onClick={()=>search()}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={()=>reset()}
              variant="contained"
              color="default"
              size="small"
              startIcon={<RestoreIcon />}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>*/}
    </Paper>
  )
}

const IndexInner = (props) => {
  const {handleApiError} = useFlash()
  const {searchQuery} = props
  const [loading, setLoading] = useState(true)

  const itemPager = newRowsPager({
    headers: ['Email', 'Created At',],
    baseQuery: searchQuery,
    onPage: ({offset, limit, baseQuery, updateRows}) => {
      const params = {
        query: {...baseQuery, offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/subscriptions`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => {
      const datetime = row.status === "active" ? row.activated_at : row.created_at
      return (
        <React.Fragment>
          {/*<TableCell>
            <Grid container alignItems="center">
              <Grid item><CustomerIcon userId={row.id} /></Grid>
              <Grid item>{row.full_name}</Grid>
            </Grid>
          </TableCell>*/}
          <TableCell>{row.email}</TableCell>
          {/*<TableCell>
            <Chip label={row.status} color={row.status==='active' ? 'primary' : 'default'} />
          </TableCell>
          <TableCell>
            <Chip label={row.payment_state} 
              color={row.payment_state==='active' ? 'secondary' : 'default'} />
          </TableCell>*/}
          <TableCell>
            {formatDistance(new Date(datetime), new Date())}
          </TableCell>
        </React.Fragment>
      )
    }
  })
  
  return (
    <React.Fragment>
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));
