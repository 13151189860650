import React, {useState, useMemo} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import CreditCardIcon from '@material-ui/icons/CreditCard'
import CheckIcon from '@material-ui/icons/Check';

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { Wrapper } from 'components/molecules/Wrap'

import {format, formatDistance, startOfMonth, endOfMonth} from 'date-fns'

import newDateTime from 'components/molecules/forms/newDateTime'

export default (props) => {
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>カード支払いオペレーション</Grid>
        </Grid>
      </Typography>
      
      <IndexOperationForm />
    </Wrapper>
  )
}

const IndexOperationForm = () => {
  const datetime = newDateTime({defaultValue: startOfMonth(new Date)})
  
  const {handleApiError, enqueueSnackbar} = useFlash()
  const handlePaymentDownload = () => {
    const params = {
      query: {issue_date: datetime.value}
    }
    Api.fetchAuth(`/orders/card_payments/payment.csv`, params).then(r=>r.blob()).then(res=>{
      const uri = URL.createObjectURL(res)
      const link = document.createElement("a")
      link.href = uri
      link.click()
    }).catch(handleApiError)
  }
  const handleRefreshDownload = () => {
    const params = {
      query: {issue_date: datetime.value}
    }
    Api.fetchAuth(`/orders/card_payments/refresh.csv`, params).then(r=>r.blob()).then(res=>{
      const uri = URL.createObjectURL(res)
      const link = document.createElement("a")
      link.href = uri
      link.click()
    }).catch(handleApiError)
  }
  const makePaymentCompleted = () => {
    const date = datetime.value
    const now = new Date()
    // const params = {query: {issue_date: date}}
    if (date >= startOfMonth(now)) {
      window.alert(`${format(now, "yyyy年M月")}の支払い処理はまだ開始されていません。`)
      return
    }
    if (window.confirm(`${format(date, "yyyy年M月")}の支払いを完了しますか？この操作はGMO管理画面で継続課金に問題がないことを確認してからおこなってください。`)) {
      Api.fetchAuth(`/orders/card_payments/${format(date, 'yyyy-MM-dd')}/complete`, {method: 'POST'}).then(r=>r.json()).then(res=>{
        enqueueSnackbar(res.message, {variant: "success"})
      }).catch(handleApiError)
    }
  }
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Chip size="small" label="月次オペレーション" color="primary" />
      <Grid container direction="row" spacing={2}>
        <Grid item md={12}>
          <FormGroup row>{datetime.render}</FormGroup>
        </Grid>
        <Grid item container xs spacing={1}>
          <Grid item>
            <Button
              onClick={handleRefreshDownload}
              variant="contained"
              color="default"
              size="small"
              startIcon={<CloudDownloadIcon />}>
              洗い替えCSV
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handlePaymentDownload}
              variant="contained"
              color="default"
              size="small"
              startIcon={<CloudDownloadIcon />}>
              継続課金CSV
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={makePaymentCompleted}
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<CheckIcon />}>
              すべての支払いを完了する
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));