import React, {useState} from 'react'
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { Wrapper } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'
// import ButtonUpload from 'components/atoms/ButtonUpload'
import ResourceStatusButton from 'components/atoms/ResourceStatusButton'
import ListItem from './parts/ListItem'

export default (props) => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  const itemPager = newRowsPager({
    headers: ['slug', 'タイトル', '公開日時', 'ステータス', 'action'],
    onPage: ({offset, limit, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/authors`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <ListItem {...row} />
        <TableCell>
          <ResourceStatusButton 
            resource_key="authors" 
            resource={row} 
            updateRowById={updateRowById} />
        </TableCell>
      </React.Fragment>
    )
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>著者一覧</Grid>
          <Grid item><IconButton component="a" href="/authors/new"><AddCircleIcon /></IconButton></Grid>
        </Grid>
      </Typography>

      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}