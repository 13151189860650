
const envs = {
  development: {
    api_prefix: process.env.REACT_APP_API_PREFIX || "http://localhost:3000/admin_api/v1",
    api_logging: true,
    preview_auth_url: process.env.REACT_APP_PREVIEW_AUTH_URL || "http://localhost:3000/authenticate_preview",
  },
  staging: {
    api_prefix: "https://staging.shinshosetsu.com/admin_api/v1",
    api_logging: true,
    preview_auth_url: "https://staging.preview.shinshosetsu.com/authenticate_preview",
  },
  production: {
    api_prefix: "https://shinshosetsu.com/admin_api/v1",
    api_logging: false,
    preview_auth_url: "https://preview.shinshosetsu.com/authenticate_preview",
  },
}

// const env = process.env.REACT_APP_RELEASE_ENV ?
//   envs[process.env.REACT_APP_RELEASE_ENV] :
//   envs['development']
const env_name = process.env.REACT_APP_RELEASE_ENV ? process.env.REACT_APP_RELEASE_ENV : 'development'
const env = {...envs[env_name], name: env_name}

export default env