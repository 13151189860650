import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newDateTime from 'components/molecules/forms/newDateTime'
import newSelect from 'components/molecules/forms/newSelect'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableSearchableLabels from 'components/molecules/forms/newSelectableSearchableLabels'
import newSelectableModalList from 'components/molecules/forms/newSelectableModalList'
import newKeyValueFields from 'components/molecules/forms/newKeyValueFields'
import newCheckbox from 'components/molecules/forms/newCheckbox'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

import AuthorListItem from 'components/pages/Authors/parts/ListItem'
import IssueListItem from 'components/pages/Issues/parts/ListItem'
import SeriesListItem from 'components/pages/Series/parts/ListItem'

import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default ({save, series, subject}) => {
  const classes = useStyles()
  subject = subject || {}

  const {handleApiError} = useFlash()

  const slug = newTextField({
    defaultValue: subject.slug || "", 
    label: "Slug", fullWidth: true,
    disabled: true,
  })
  const title = newTextField({
    defaultValue: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const writing_kind = newSelect({
    defaultValue: subject.writing_kind, 
    options: {vertical: "縦書き", horizontal: "横書き"},
    label: "ライティング", required: true,
  })
  const display_genre = newTextField({
    defaultValue: subject.display_genre || "", 
    label: "表示ジャンル", fullWidth: true, required: false,
    helperText: "ラインナップや一覧に表示されるジャンルを自由記述します",
  })
  const summary = newTextField({
    defaultValue: subject.summary || "", 
    label: "紹介文", fullWidth: true, 
    multiline: true, rows: 3,
  })

  // const content_css_class = newTextField({
  //   defaultValue: subject.content_css_class || "", 
  //   label: "cssクラス", fullWidth: true,
  // })
  const content = newHtmlEditor({
    defaultValue: subject.content || "", label: "本文html",
    assetsProps: {baseQuery: {kind: 'article'}}
  })
  const placedContent = newHtmlEditor({
    defaultValue: subject.placed_content || "", label: "固定html",
    assetsProps: {baseQuery: {kind: 'article'}},
    editorProps: {
      className: classes.placedContent,
    }
  })
  const issue = newSelectableModalList({
    label: "刊行",
    max: 1, defaultValue: subject.issue,
    modalListProps: {
      headers: ['', 'slug', 'タイトル', '公開日時', 'ステータス'],
      ListItemComponent: (props) => (<IssueListItem {...props} />),
      onPage: ({offset, limit, updateRows}) => {
        const params = {
          query: {offset, limit}
        }
        Api.fetchAuth(`/issues`, params).then(r=>r.json()).then(response=>{
          updateRows(response.data, response.total)
        }).catch(handleApiError)
      },
    }
  })
  const author = newSelectableModalList({
    label: "著者",
    max: 1, defaultValue: subject.author,
    modalListProps: {
      headers: ['', 'slug', '名前', '公開日時', 'ステータス'],
      ListItemComponent: (props) => (<AuthorListItem {...props} />),
      onPage: ({offset, limit, updateRows}) => {
        const params = {
          query: {offset, limit}
        }
        Api.fetchAuth(`/authors`, params).then(r=>r.json()).then(response=>{
          updateRows(response.data, response.total)
        }).catch(handleApiError)
      },
    }
  })
  const seriesSelector = newSelectableModalList({
    label: "シリーズ",
    max: 1, 
    defaultValue: series || subject.series,
    modalListProps: {
      headers: ['', 'slug', 'タイトル', '公開日時', 'ステータス'],
      ListItemComponent: (props) => (<SeriesListItem {...props} />),
      onPage: ({offset, limit, updateRows}) => {
        const params = {
          query: {offset, limit}
        }
        Api.fetchAuth(`/series`, params).then(r=>r.json()).then(response=>{
          updateRows(response.data, response.total)
        }).catch(handleApiError)
      },
    }
  })
  const series_number = newTextField({
    defaultValue: subject.series_number || "", 
    label: "シリーズ番号", disabled: true,
    type: 'number',
  })
  const image = newSelectableAssets({
    default: subject.image ? [subject.image] : [],
    max: 1,
    baseQuery: {kind: 'article'}
  })
  const variables = newKeyValueFields({
    defaultValue: subject.variables, label: "変数",
  })
  const variables_membership = newKeyValueFields({
    defaultValue: subject.variables_membership, label: "変数（会員向け）",
  })
  const isolated = newCheckbox({
    defaultValue: subject.isolated, label: "孤立",
    helperText: "チェックするとコンテンツ一覧に表示されません"
  })
  const free = newCheckbox({
    defaultValue: subject.free, label: "無料",
    helperText: "チェックすると無料会員でも全文読めるコンテンツとなります"
  })
  const hide_blur_for_guest = newCheckbox({
    defaultValue: subject.hide_blur_for_guest, label: "ブラー非表示",
    helperText: "チェックするとブラーを非表示にします"
  })
  const tags = newSelectableSearchableLabels({
    label: "タグ", max: 10,
    defaultValue: subject.tags || [],
    prefix: '/a/tag',
  })

  const body = {
    // slug: slug.value,
    title: title.value,
    writing_kind: writing_kind.value,
    display_genre: display_genre.value,
    summary: summary.value,
    content: content.html,
    issue_slug: issue.values[0] && issue.values[0].slug,
    author_slug: author.values[0] && author.values[0].slug,
    series_slug: seriesSelector.values[0] && seriesSelector.values[0].slug,
    // series_number: series_number.value,
    image_id: image.values[0] && image.values[0].id,
    placed_content: placedContent.html,
    isolated: isolated.value,
    free: free.value,
    hide_blur_for_guest: hide_blur_for_guest.value,
    variables: variables.values,
    variables_membership: variables_membership.values,

    label_ids: tags.values.map(x=>x.id),
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug, title, writing_kind, display_genre,
      issue, author, seriesSelector, series_number,
      summary,
      content,
      image,
      (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Advanced Setting</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>{placedContent.render}</Grid>
              <Grid item xs={12}>{variables.render}</Grid>
              <Grid item xs={12}>{variables_membership.render}</Grid>
              <Grid item xs={12}>{isolated.render}</Grid>
              <Grid item xs={12}>{free.render}</Grid>
              <Grid item xs={12}>{hide_blur_for_guest.render}</Grid>

              <Grid item xs={12}>{tags.render}</Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    ]} />
  )
}

const useStyles = makeStyles(theme => ({
  placedContent: {
    '&>.CodeMirror': {
      height: '180px',
    }
  },
}))