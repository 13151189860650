import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import useUpdater from 'modules/useUpdater'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'

import GeneratedForm from 'components/organisms/GeneratedForm'
import newSelectableSortableList from 'components/molecules/forms/newSelectableSortableList'

export default (props) => {
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [subject, setSubject] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)
    
    const subject = Api.fetchAuth(`/issues/${id}`).then(r=>r.json())

    Promise.all([subject]).then(responses=>{
      setSubject(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [lastUpdatedAt])

  const save = (body) => {
    console.log('body', body)
    return Api.fetchAuth(`/issues/${id}/lineups`, {
      method: 'PATCH',
      body: body
    }).then(r=>r.json()).then(response=>{
      update()
      return Promise.resolve()
    }).catch((error)=>{
      handleApiError(error)
      return Promise.resolve()
    })
  }

  if (loading) return <CircularProgress />

  return (
    <WrapForm>
      <InfoPaper>
        <Grid container direction="row" spacing={2}>
          <Grid item md={12}>
            <Link href={`/issues/${id}/lineups`}>ラインナップを設定</Link>
          </Grid>
        </Grid>
      </InfoPaper>
      <Typography variant="h6" gutterBottom>ラインナップ編集</Typography>
      <FormLineups {...{save, subject: subject}} />
    </WrapForm>
  )
}

const FormLineups = ({save, subject}) => {
  const list = newSelectableSortableList({
    defaultValue: subject.lineup_articles,
    options: subject.articles,
    Component: ({index, title, slug,}) => (
      <ListItemText primary={title} secondary={slug} />
    )
  })

  const body = {
    lineups: list.values.map(x=>x.slug)
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      list,
    ]} />
  )
}