import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';
import {InfoPaper} from 'components/molecules/Wrap'
import useSuperCache from 'modules/useSuperCache'
import AuthToken from 'utils/AuthToken'
import Env from 'utils/Env'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    '& svg': {
      marginRight: theme.spacing(1),
    }
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default () => {
  const {me} = useSuperCache()
  const {party} = me
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <PreviewAuthForm />
      </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
      <Grid item xs={12} md={4}>
      </Grid>

      <Grid item xs={12}>
      </Grid>

      <Grid item xs={12}>
      </Grid>
    </Grid>
  )
}

const PreviewAuthForm = () => {
  return (
    <InfoPaper>
      <form method="POST" action={Env.preview_auth_url}>
        <input
          type="hidden"
          name="auth_token"
          value={AuthToken.token}
        />
        <Button component='button' type='submit'
          startIcon={<PageviewIcon />}>プレビューサイトにログイン</Button>
      </form>
    </InfoPaper>
  )
}
