
export const normalizePath = (value) => {
  const extracted = (value || "").split('/').filter(x=>x).join('/')
  return `/${extracted}`
}

export const jpyFormat = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY',
})

export default {
  normalizePath,
}