import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import {WrapForm, Section} from 'components/molecules/Wrap'

import useImages from 'modules/useImages'
import AssetCard from 'components/molecules/AssetCard'
import Uploader from 'components/molecules/forms/Uploader'

import Checkbox from 'components/atoms/Checkbox'

const useModalStyles = makeStyles(theme => ({
  contentArea: {
    maxWidth: '100%',
    width: 320,
    height: '100%',
  },
  block: {
    marginBottom: theme.spacing(2),
  }
}))

const useAssetStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& img': {
      maxWidth: 300,
    },
  },
  gridList: {
    maxHeight: 450,
    overflowY: 'scroll',
    marginBottom: theme.spacing(2),
  },
  selector: {
    backgroundColor: theme.palette.primary.light,
  },
  assetCard: {
    opacity: 0.7,
  },
}))

export default (props) => {
  // const [uploadOriginal, setUploadOriginal] = useState(false)
  const classes = useAssetStyles()
  const assetsModule = useImages({baseQuery: {}, uploadOriginal: true})
  const [selectedAsset, selectAsset] = useState(null)
  const resetAsset = () => selectAsset(null)

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="agl-modal-title"
        aria-describedby="agl-modal-description"
        open={!!selectedAsset}
        onClose={()=>resetAsset()}>
        <div className={classes.modalPaper}>
          <ModalInner item={selectedAsset} onClick={()=>resetAsset()} />
        </div>
      </Modal>
      <Grid container spacing={2}>
        {/*<Grid item xs={12}>
          <Checkbox
            checked={uploadOriginal}
            onChange={()=>setUploadOriginal(!uploadOriginal)}
            label="オリジナル画像も同時アップロード"/>
        </Grid>*/}
        <Grid item xs={12}>
          <Uploader onDrop={assetsModule.onDrop} />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.gridList} spacing={1}>
            {assetsModule.assets.map((item,i) => (
              <Grid item key={i} xs={4} sm={3} md={2}>
                <AssetCard item={item}
                  onClick={(item)=>selectAsset(item)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const ModalInner = ({item, onClick}) => {
  const classes = useModalStyles()
  const urls = {main: item.publish_url, ...item.variant_urls}
  return (
    <React.Fragment>
      <h2 id="agl-modal-title">{item.title}</h2>

      {Object.keys(urls).map((k,i)=>{
        const url = urls[k]
        const elementId = `copyUrl${i}`
        return (
          <div className={classes.block}>
            <TextField value={url} id={elementId} />
            <Button onClick={()=>{
              const target = document.getElementById(elementId);
              target.select()
              target.setSelectionRange(0, 99999)
              document.execCommand("copy")
            }}>Copy</Button>
            <IconButton size="small" component="a" target="_blank" href={url}>
              <OpenInNewIcon />
            </IconButton>
            &nbsp;
            <Chip size="small" label={k} />
          </div>
        )
      })}

      <div className={classes.contentArea} onClick={onClick}>
        <AssetCard item={item} onClick={()=>{}} />
      </div>
    </React.Fragment>
  )
}
