import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import useUpdater from 'modules/useUpdater'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'
import InfoTable from 'components/molecules/InfoTable'

import {currency} from 'utils/Util'

import {format} from 'date-fns'

export default (props) => {
  const id = props.match.params.id
  const {enqueueSnackbar, handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)

    const subject = Api.fetchAuth(`/users/${id}`).then(r=>r.json())
    Promise.all([subject]).then(responses=>{
      setUser(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  if (loading) return (<CircularProgress />)

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>User</Typography>

      <Action user={user} onUpdate={setUser} />

      <Grid container spacing={3}>
        <Grid container item md={6} direction="column" spacing={3}>
          <Grid item>
            <CustomerInformation user={user} />
          </Grid>
          <Grid item>
            <MakeSpecialButton
              user={user}
              onUpdate={setUser}
              enqueueSnackbar={enqueueSnackbar}
              handleApiError={handleApiError}/>
          </Grid>
        </Grid>

        {/*<Grid container item md={6} direction="column" spacing={3}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h6" gutterBottom>Contact Information</Typography>
              </Grid>
              <AddressInformation profile={user.address} />
            </Grid>
          </Grid>
        </Grid>*/}
      </Grid>
    </WrapForm>
  )
}

const Action = ({user, onUpdate}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item>No available actions for now</Grid>
      </Grid>
    </Paper>
  )
}

const CustomerInformation = ({user}) => {
  const {
    email, gender, birthday, status, payment_state, payment_method
  } = user
  const full_name = user.full_name
  const info = {
    name: full_name,
    email, gender,
    birthday: birthday ? format(new Date(birthday), 'yyyy/MM/dd') : '-',
    status: (<Chip label={status} color={status==='active' ? 'primary' : 'default'} />),
    payment_state: (<Chip label={payment_state} color={status==='active' ? 'secondary' : 'default'} />),
    payment_method: (<Chip label={payment_method} color='default' />),
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6" gutterBottom>Basic Information</Typography>
      </Grid>
      {/*<Grid item>
        <Chip label={customer_token} />
      </Grid>*/}
      <Grid item>
        <InfoTable
          rows={Object.keys(info).map(k=>({
            title: k,
            content: info[k],
          }))} />
      </Grid>
    </Grid>
  )
}

// const AddressInformation = ({profile}) => {
//   const {
//     postal_code,
//     address2, address1, city, region,
//     first_name, last_name,
//     tel,
//   } = profile
//   const address = {postal_code, address2, address1, city, region}
//   const name = {first_name, last_name}
//   const info = Object.assign({}, address, name, {tel})

//   return (
//     <React.Fragment>
//       <Grid item>
//         <InfoTable
//           rows={Object.keys(info).map(k=>({
//             title: k.replace('customer_', ''),
//             content: info[k],
//           }))} />
//       </Grid>
//       <Grid item>
//         <TextField
//           fullWidth
//           label="Full Address"
//           margin="dense"
//           variant="outlined"
//           multiline
//           rowsMax="4"
//           value={[
//             Object.values(address).join(' '),
//             Object.values(name).join(' '),
//           ].join("\n")}
//           disabled
//         />
//       </Grid>
//     </React.Fragment>
//   )
// }

const MakeSpecialButton = ({user, onUpdate, enqueueSnackbar, handleApiError}) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6" gutterBottom>Making Special Account</Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={()=>{registerCorporateAccount(user.id, user.full_name, onUpdate, enqueueSnackbar, handleApiError)}}
          variant="contained"
          color="primary"
          size="large">
          APPLY (Corporate Account)
        </Button>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));

const registerCorporateAccount = (id, name, onUpdate, enqueueSnackbar, handleApiError) => {
  if(!window.confirm("ユーザ: "+name+"を企業アカウントにしてもよろしいですか？")) return
  Api.fetchAuth(`/users/${id}/make_special`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'}
  }).then(r=>r.json()).then(res=>{
    onUpdate(res.data);
    enqueueSnackbar('Succeeded making special payment account', {variant: 'success', persist: false})
  }).catch(handleApiError)
}
