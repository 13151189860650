import React from 'react'
import { withTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import DoneIcon from '@material-ui/icons/Done';
import PaymentIcon from '@material-ui/icons/Payment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';

export const OrderStatus = ({order}) => {
  if (order.status === 'opened') {
    return <OrderStatusOpenedIcon />
  } else if (order.status === 'canceled') {
    return <OrderStatusCanceledIcon />
  }
}

export const OrderPaymentState = ({order}) => {
  if (order.payment_state === 'pending') {
    return <OrderPaymentStatePendingIcon disabled={order.status === 'canceled'} />
  } else if (order.payment_state === 'completed') {
    return <OrderPaymentStateCompletedIcon disabled={order.status === 'canceled'} />
  } else {
    return <OrderPaymentStateDefaultIcon label={order.payment_state} disabled={order.status === 'canceled'} />
  }
}

export const OrderPaymentMethod = ({order}) => {
  if (order.payment_method === 'card') {
    return <OrderPaymentMethodCardIcon disabled={order.status === 'canceled'} />
  } else if (order.payment_method === 'amazonpay') {
    return <OrderPaymentMethodAmazonpayIcon disabled={order.status === 'canceled'} />
  } else {
    return <OrderPaymentMethodDefaultIcon label={order.payment_method} disabled={order.status === 'canceled'} />
  }
}

const OrderStatusOpenedIcon = () => (
  <Chip size="small" label={"opened"} />
)
const OrderStatusCanceledIcon = () => (
  <Chip size="small" label={"canceled"} color="secondary" />
)

const OrderPaymentStatePendingIcon = (props) => (
  <Chip size="small" label={"pending"} color="secondary" icon={<PaymentIcon />} {...props} />
)
const OrderPaymentStateCompletedIcon = (props) => (
  <Chip size="small" label={"completed"} color="primary" icon={<PaymentIcon />} {...props} />
)
const OrderPaymentStateDefaultIcon = (props) => (
  <Chip size="small" color="primary" icon={<PaymentIcon />} {...props} />
)

const OrderPaymentMethodCardIcon = (props) => (
  <Chip size="small" label={"card"} color="default" icon={<PaymentIcon />} {...props} />
)
const OrderPaymentMethodAmazonpayIcon = (props) => (
  <Chip size="small" label={"amazon pay"} color="default" icon={<PaymentIcon />} {...props} />
)
const OrderPaymentMethodDefaultIcon = (props) => (
  <Chip size="small" color="default" icon={<PaymentIcon />} {...props} />
)

// export const UserName = ({order}) => {
//   const {first_name, last_name} = order.customer_address
//   return (
//     <Grid container direction="row" alignItems="center" spacing={1}>
//       <Grid item>{`${first_name} ${last_name}`}</Grid>
//       {order.customer_kind === 'user' && (
//         <Grid item>
//           <IconButton component="a" href={`/users/${order.user.id}`}>
//             <AccountCircleIcon fontSize="small" />
//           </IconButton>
//         </Grid>
//       )}
//     </Grid>
//   )
// }

export default {
  OrderStatus, OrderPaymentState, OrderPaymentMethod
}

const useStyles = makeStyles(theme => ({
}));
