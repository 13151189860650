import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import useUpdater from 'modules/useUpdater'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'
import InfoTable from 'components/molecules/InfoTable'
// import DetailItem from './parts/DetailItem'

import {
  OrderStatus, 
  OrderPaymentState, OrderPaymentMethod, 
  UserName,
} from 'components/atoms/icons/OrderIcon'
import CustomerName from 'components/atoms/CustomerName'
import {
  OrderStatusButton, OrderPaymentStateButton,
} from 'components/atoms/OrderButtons'
// import PriceTag from 'components/atoms/icons/PriceTag'
// import Form from './parts/Form'

import {jpyFormat} from 'utils/Util'

export default (props) => {
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)

    const subject = Api.fetchAuth(`/orders/${id}`).then(r=>r.json())
    Promise.all([subject]).then(responses=>{
      setOrder(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  // const save = (body) => {
  //   console.log('body', body)
  //   setLoading(true)
  //   return Api.fetchAuth(`/orders/${id}`, {
  //     method: 'PUT',
  //     body: body
  //   }).then(r=>r.json()).then(response=>{
  //     update()
  //     // setLoading(false)
  //     // window.location.href = '/orders'
  //   }).catch((error)=>{
  //     setLoading(false)
  //     handleApiError(error)
  //   })
  // }

  if (loading) return (<CircularProgress />)

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>支払い</Typography>

      <Action order={order} onUpdate={setOrder} />
      
      <Grid container spacing={3}>
        <Grid container item md={6} direction="column" spacing={3}>
          <Grid item>
            <CustomerInformation order={order} />
          </Grid>
          {/*<Grid item></Grid>*/}
        </Grid>

        <Grid item>
          <DetailInformation order={order} />
        </Grid>
      </Grid>
      <Grid container>
        <PaymentInformation order={order} />
      </Grid>
    </WrapForm>
  )
}

const Action = ({order, onUpdate}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        {/*<Grid item>
          <OrderStatusButton order={order} onUpdate={onUpdate} />
        </Grid>*/}
        {/* NOTE: Develop this soon.
        <Grid item>
          <OrderPaymentStateButton order={order} onUpdate={onUpdate} />
        </Grid>*/}
      </Grid>
    </Paper>
  )
}

const CustomerInformation = ({order}) => {
  const nameProps = {
    full_name: order.user.full_name,
    userId: order.user && order.user.id
  }
  const info = {
    customer_name: (<CustomerName {...nameProps} />),
    customer_email: order.user.email, 
    customer_gender: order.user.gender,
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6" gutterBottom>Customer Information</Typography>
      </Grid>
      <Grid item>
        <InfoTable 
          rows={Object.keys(info).map(k=>({
            title: k.replace('customer_', ''), 
            content: info[k],
          }))} />
      </Grid>
    </Grid>
  )
}

const PaymentInformation = ({order}) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          <Grid container spacing={2}>
            <Grid item>Payment Information</Grid>
            <Grid item><OrderPaymentState order={order} /></Grid>
            <Grid item><OrderPaymentMethod order={order} /></Grid>
          </Grid>
        </Typography>
      </Grid>
      {/*<Grid item>
        <Chip label={order.payment_method} />
      </Grid>*/}
      
    </Grid>
  )
}

const DetailInformation = ({order}) => {
  const {
    total_price, total_payment, total_discount, total_tax
  } = order
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          <Grid container spacing={2}>
            <Grid item>Detail Information</Grid>
            
          </Grid>
        </Typography>
      </Grid>
      <Grid item>
        <InfoTable 
          align
          rows={[
            {title: 'Total Price', subcontent: jpyFormat.format(total_price)},
            // {title: 'Shipping Fee', subcontent: jpyFormat.format(total_shipping_fee)},
            {title: 'Discount', subcontent: jpyFormat.format(total_discount)},
            {title: 'Tax', subcontent: jpyFormat.format(total_tax)},
            {subcontent: (
              <Typography><b>Total: {jpyFormat.format(total_payment)}</b></Typography>
            )},
          ]} />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));
