import React from 'react'
import Grid from '@material-ui/core/Grid'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'

export default ({full_name, userId}) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      {userId && (<CustomerIcon userId={userId} />)}
      <Grid item>{`${full_name}`}</Grid>
    </Grid>
  )
}

export const CustomerIcon = ({userId}) => (
  <Grid item>
    <IconButton component="a" href={`/users/${userId}`}>
      <AccountCircleIcon fontSize="small" />
    </IconButton>
  </Grid>
)