import React, {useState} from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
// import Typography from '@material-ui/core/Typography';
// import TableCell from '@material-ui/core/TableCell';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
// import Chip from '@material-ui/core/Chip';
import newTextField from 'components/molecules/forms/newTextField'
import newSelect from 'components/molecules/forms/newSelect'

export default ({save, subject}) => {
  subject = subject || {}

  const slug = newTextField({
    defaultValue: subject.slug || "", 
    label: "Slug", fullWidth: true, required: true,
    disabled: !! subject.slug,
    helperText: "アルファベット小文字・数字・ハイフンのみ使用できます",
  })
  const title = newTextField({
    defaultValue: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const content_type = newTextField({
    defaultValue: subject.content_type,
    label: "タイプ", fullWidth: true,
    helperText: "Ex: application/json text/html",
  })
  const form_kind = newSelect({
    defaultValue: (subject.metadata || {}).form,
    options: {
      "code": "code",
      "link_list": "link_list",
    },
    label: "フォーム種別", 
  })

  const body = {
    slug: slug.value,
    title: title.value,
    content_type: content_type.value,
    metadata: {
      form: form_kind.value,
    }
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug, title, 
      content_type, form_kind,
    ]} />
  )
}
