import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import useUpdater from 'modules/useUpdater'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'

import Form from './parts/Form'

export default (props) => {
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [subject, setSubject] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)
    
    const subject = Api.fetchAuth(`/events/${id}`).then(r=>r.json())

    Promise.all([subject]).then(responses=>{
      setSubject(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [lastUpdatedAt])

  const save = (body) => {
    console.log('body', body)
    return Api.fetchAuth(`/events/${id}`, {
      method: 'PUT',
      body: body
    }).then(r=>r.json()).then(response=>{
      update()
      return Promise.resolve()
    }).catch((error)=>{
      handleApiError(error)
      return Promise.resolve()
    })
  }

  if (loading) return <CircularProgress />

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>イベント編集</Typography>
      <Form {...{save, subject: subject}} />
    </WrapForm>
  )
}
