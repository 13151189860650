import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'

import Form from './parts/Form'

export default () => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setLoading(false)
    
    // Promise.all([]).then(responses=>{
    //   setLoading(false)
    // }).catch(error=>{
    //   setLoading(false)
    //   handleApiError(error)
    // })
  }, [1])

  const save = (body) => {
    console.log('body', body)
    return Api.fetchAuth('/news', {
      method: 'POST',
      body: body
    }).then(r=>r.json()).then(response=>{
      window.location.href = '/news'
    }).catch((error)=>{
      handleApiError(error)
    })
  }

  if (loading) return <CircularProgress />

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>News</Typography>
      <Form {...{save}} />
    </WrapForm>
  )
}
