import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    '& *': {
      // color: theme.palette.primary.light,
      color: theme.palette.common.white,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'bold',
    }
  },
}))

export default ({title, rows, cellProps}) => {
  const classes = useStyles()
  return (
    <Table size="small">
      {title && <TableHead color="default" size="small" className={classes.head}>
        <TableRow>
          <TableCell colSpan="3">
            {typeof title === "string" ? <Typography>{title}</Typography> : title}
          </TableCell>
        </TableRow>
      </TableHead>}
      <TableBody>
        {
          rows.map((row,i)=>{
            return (
              <TableRow key={i}>
                <TableCell component="th" scope="row">{row.title}</TableCell>
                <TableCell>{row.content}</TableCell>
                <TableCell align="right">{row.subcontent}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
}