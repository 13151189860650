import React, {useState,useEffect} from 'react'
import { 
  BrowserRouter as Router, 
  Route, Redirect, Switch 
} from "react-router-dom"
import logo from './logo.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Button from '@material-ui/core/Button'
import useSuperCache, {SuperCacheProvider} from './modules/useSuperCache'
import {FlashProvider} from './modules/useFlash'
import ThemeProvider from './ThemeProvider'
// import Header from './components/organisms/Header'
import Dashboard from './components/organisms/Dashboard'
import Standalone from './components/organisms/Standalone'
import DashboardPage from './components/pages/DashboardPage'
import AccountPermissionsPage from './components/pages/Account/PermissionsPage'

import SharedAssetsIndexPage from './components/pages/Shared/Assets/IndexPage'

import LabelsNewPage from 'components/pages/Labels/NewPage'
import LabelsEditPage from 'components/pages/Labels/EditPage'
import LabelsIndexPage from 'components/pages/Labels/IndexPage'
import LabelsUploadPage from 'components/pages/Labels/UploadPage'

import NewsIndexPage from 'components/pages/News/IndexPage'
import NewsNewPage from 'components/pages/News/NewPage'
import NewsEditPage from 'components/pages/News/EditPage'
import EventReportsIndexPage from 'components/pages/EventReports/IndexPage'
import EventReportsNewPage from 'components/pages/EventReports/NewPage'
import EventReportsEditPage from 'components/pages/EventReports/EditPage'
import EventsIndexPage from 'components/pages/Events/IndexPage'
import EventsNewPage from 'components/pages/Events/NewPage'
import EventsEditPage from 'components/pages/Events/EditPage'

import AuthorsIndexPage from 'components/pages/Authors/IndexPage'
import AuthorsNewPage from 'components/pages/Authors/NewPage'
import AuthorsEditPage from 'components/pages/Authors/EditPage'
import IssuesIndexPage from 'components/pages/Issues/IndexPage'
import IssuesNewPage from 'components/pages/Issues/NewPage'
import IssuesEditPage from 'components/pages/Issues/EditPage'
import IssuesLineupsEditPage from 'components/pages/Issues/EditLineupsPage'
import SeriesIndexPage from 'components/pages/Series/IndexPage'
import SeriesNewPage from 'components/pages/Series/NewPage'
import SeriesEditPage from 'components/pages/Series/EditPage'
import ArticlesIndexPage from 'components/pages/Articles/IndexPage'
import ArticlesNewPage from 'components/pages/Articles/NewPage'
import ArticlesEditPage from 'components/pages/Articles/EditPage'

import OrdersIndexPage from 'components/pages/Orders/IndexPage'
import OrdersEditPage from 'components/pages/Orders/EditPage'
import OrdersCardPaymentOperationPage from 'components/pages/Orders/CardPaymentOperationPage'

import UsersIndexPage from 'components/pages/Users/IndexPage'
import UsersEditPage from 'components/pages/Users/EditPage'

import SubscriptionsIndexPage from 'components/pages/Subscriptions/IndexPage'

import ComponentsNewPage from 'components/pages/Components/NewPage'
import ComponentsEditPage from 'components/pages/Components/EditPage'
import ComponentsIndexPage from 'components/pages/Components/IndexPage'
import ComponentDataEditPage from 'components/pages/Components/Data/EditPage'

import ArchiveSeriesIndexPage from 'components/pages/ArchiveSeries/IndexPage'
import ArchiveSeriesNewPage from 'components/pages/ArchiveSeries/NewPage'
import ArchiveSeriesEditPage from 'components/pages/ArchiveSeries/EditPage'

import ArchiveIndexPage from 'components/pages/Archive/IndexPage'
import ArchiveNewPage from 'components/pages/Archive/NewPage'
import ArchiveEditPage from 'components/pages/Archive/EditPage'

export default (props) => {
  return (
    <SuperCacheProvider>
      <Main {...props} />
    </SuperCacheProvider>
  )
}

export const Main = ({me}) => {
  const [loading, setLoading] = useState(true)
  const {updateMe} = useSuperCache()
  useEffect(()=>{
    updateMe(me)
    setLoading(false)
  }, [me])
  const {staff, permission} = me

  if (loading) return (<CircularProgress />)
  return (
    <Router>
      <ThemeProvider>
        <FlashProvider>
        {/*<Header />*/}
          {
            permission ? 
              (<Dashboard>{PermittedRoute({me})}</Dashboard>) : 
              (<Standalone>{UnPermittedRoute({me})}</Standalone>) 
          }
        </FlashProvider>
      </ThemeProvider>
    </Router>
  );
}

const PermittedRoute = ({me}) => {
  const {staff, permission} = me
  return (
    <Switch>
      <Route path="/account/permissions" exact component={AccountPermissionsPage} />
      <Route path="/" exact component={DashboardPage} />

      <Route path="/shared/assets" exact component={SharedAssetsIndexPage} />

      <Route path="/labels/new" exact component={LabelsNewPage} />
      <Route path="/labels/new/*" exact component={LabelsNewPage} />
      <Route path="/labels/upload" exact component={LabelsUploadPage} />
      <Route path="/labels/prefix/*" exact component={LabelsIndexPage} />
      <Route path="/labels/:id" exact component={LabelsEditPage} />
      <Route path="/labels" exact component={LabelsIndexPage} />

      <Route path="/users/" exact component={UsersIndexPage} />
      <Route path="/users/:id" exact component={UsersEditPage} />
      <Route path="/subscriptions/" exact component={SubscriptionsIndexPage} />
      <Route path="/orders/" exact component={OrdersIndexPage} />
      <Route path="/orders/card_payment_operation" exact component={OrdersCardPaymentOperationPage} />
      <Route path="/orders/:id" exact component={OrdersEditPage} />

      <Route path="/news" exact component={NewsIndexPage} />
      <Route path="/news/new" exact component={NewsNewPage} />
      <Route path="/news/:id" exact component={NewsEditPage} />
      <Route path="/event_reports" exact component={EventReportsIndexPage} />
      <Route path="/event_reports/new" exact component={EventReportsNewPage} />
      <Route path="/event_reports/:id" exact component={EventReportsEditPage} />
      <Route path="/events" exact component={EventsIndexPage} />
      <Route path="/events/new" exact component={EventsNewPage} />
      <Route path="/events/:id" exact component={EventsEditPage} />
      <Route path="/authors" exact component={AuthorsIndexPage} />
      <Route path="/authors/new" exact component={AuthorsNewPage} />
      <Route path="/authors/:id" exact component={AuthorsEditPage} />
      <Route path="/issues" exact component={IssuesIndexPage} />
      <Route path="/issues/new" exact component={IssuesNewPage} />
      <Route path="/issues/:id" exact component={IssuesEditPage} />
      <Route path="/issues/:id/lineups" exact component={IssuesLineupsEditPage} />
      <Route path="/series" exact component={SeriesIndexPage} />
      <Route path="/series/new" exact component={SeriesNewPage} />
      <Route path="/series/:id" exact component={SeriesEditPage} />
      <Route path="/series/:series_id/articles/new" exact component={ArticlesNewPage} />
      <Route path="/series/:series_id/articles" exact component={ArticlesIndexPage} />
      <Route path="/articles" exact component={ArticlesIndexPage} />
      <Route path="/articles/:id" exact component={ArticlesEditPage} />

      <Route path="/components" exact component={ComponentsIndexPage} />
      <Route path="/components/new" exact component={ComponentsNewPage} />
      <Route path="/components/:id" exact component={ComponentsEditPage} />
      <Route path="/components/:id/data" exact component={ComponentDataEditPage} />

      <Route path="/archive_series" exact component={ArchiveSeriesIndexPage} />
      <Route path="/archive_series/new" exact component={ArchiveSeriesNewPage} />
      <Route path="/archive_series/:id" exact component={ArchiveSeriesEditPage} />

      <Route path="/archives" exact component={ArchiveIndexPage} />
      <Route path="/archives/new" exact component={ArchiveNewPage} />
      <Route path="/archives/:id" exact component={ArchiveEditPage} />

      <Route render={props=><Redirect to="/" />} />
    </Switch>
  )
}

const UnPermittedRoute = ({me}) => {
  const {staff, permission} = me
  return (
    <Switch>
      <Route path="/account/permissions" exact component={AccountPermissionsPage} />
      <Route render={props=><Redirect to="/account/permissions" />} />
    </Switch>
  )
}