import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import newTextField from 'components/molecules/forms/newTextField'
import newDateTime from 'components/molecules/forms/newDateTime'
// import newSelect from 'components/molecules/forms/newSelect'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
// import newRichEditor from 'components/molecules/forms/newRichEditor'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableSearchableLabels from 'components/molecules/forms/newSelectableSearchableLabels'
import newDateTimeRange from 'components/molecules/forms/newDateTimeRange'

import HelpTip from 'components/atoms/HelpTip'
import Api from 'utils/Api'
import InputLabel from '@material-ui/core/InputLabel'

export default ({save, subject}) => {
  subject = subject || {}

  const publishRange = newDateTimeRange({
    startAtProps: { defaultValue: subject.start_at, required: true },
    endAtProps: { defaultValue: subject.end_at || "2099-12-31", required: true },
  })
  const slug = newTextField({
    default: subject.slug || "", 
    label: "Slug", fullWidth: true, disabled: true,
  })
  const title = newTextField({
    default: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  // const content = newRichEditor({defaultValue: subject.content || "", label: ""})
  const content = newHtmlEditor({defaultValue: subject.content || "", label: ""})
  // const image = newSelectableAssets({
  //   default: subject.images,
  //   max: 1,
  //   baseQuery: {
  //     kind: 'news',
  //     // content_type: 'image',
  //   }
  // })

  const body = {
    start_at: publishRange.startAt.value,
    end_at: publishRange.endAt.value,
    title: title.value,
    content: content.html,
    // image_ids: image.hasValues() ? [image.values[0].id] : [],
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      publishRange, slug,
      title, 
      content,
      // image,
    ]} />
  )
}