import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newDateTime from 'components/molecules/forms/newDateTime'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableSearchableLabels from 'components/molecules/forms/newSelectableSearchableLabels'
import ArticleListItem from 'components/pages/Articles/parts/ListItem'
import newSelectableModalList from 'components/molecules/forms/newSelectableModalList'
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

export default ({save, subject}) => {
  const classes = useStyles()
  const {handleApiError} = useFlash()
  subject = subject || {}

  const slug = newTextField({
    defaultValue: subject.slug || "", 
    label: "Slug", fullWidth: true,
    disabled: true,
  })
  const title = newTextField({
    defaultValue: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const description = newTextField({
    defaultValue: subject.description || "", 
    label: "説明", fullWidth: true
  })
  const image = newSelectableAssets({
    default: subject.image ? [subject.image] : [],
    max: 1,
    baseQuery: {kind: 'series'}
  })

  const content_css_class = newTextField({
    defaultValue: subject.content_css_class || "", 
    label: "cssクラス", fullWidth: true,
  })
  const common_css = newHtmlEditor({
    defaultValue: subject.common_css || "", label: "共通css",
    assetsProps: {baseQuery: {kind: 'series'}},
    editorProps: {
      className: classes.editor,
    }
  })
  const genres = newSelectableSearchableLabels({
    label: "ジャンル", max: 10,
    defaultValue: subject.genres || [],
    prefix: '/series/genre',
  })
  // const image = newSelectableAssets({
  //   default: subject.image ? [subject.image] : [],
  //   max: 1,
  //   baseQuery: {kind: 'series'}
  // })
  const standalone_article = newSelectableModalList({
    label: "非連載に設定（記事を作成後、ここで設定します）",
    max: 1, defaultValue: subject.standalone_article,
    modalListProps: {
      headers: ['', 'slug', 'タイトル', '公開日時', 'ステータス'],
      ListItemComponent: (props) => (<ArticleListItem {...props} />),
      onPage: ({offset, limit, updateRows}) => {
        const params = {
          query: {offset, limit}
        }
        Api.fetchAuth(`/articles`, params).then(r=>r.json()).then(response=>{
          updateRows(response.data, response.total)
        }).catch(handleApiError)
      },
    }
  })

  const body = {
    // slug: slug.value,
    title: title.value,
    description: description.value,
    image_id: image.values.map(x=>x.id)[0],
    content_css_class: content_css_class.value,
    common_css: common_css.value,
    standalone_article_slug: standalone_article.values[0] && standalone_article.values[0].slug,
    genre_ids: genres.values.map(x=>x.id),
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug,
      title,
      description,
      image,
      content_css_class,
      common_css,
      genres,
      standalone_article,
    ]} />
  )
}

const useStyles = makeStyles(theme=>({
  editor: {
    '&>.CodeMirror': {height: '180px'},
  }
}))
