import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import newTextField from 'components/molecules/forms/newTextField'
import newDateTime from 'components/molecules/forms/newDateTime'
// import newSelect from 'components/molecules/forms/newSelect'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
// import newRichEditor from 'components/molecules/forms/newRichEditor'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableSearchableLabels from 'components/molecules/forms/newSelectableSearchableLabels'
import newDateTimeRange from 'components/molecules/forms/newDateTimeRange'

import HelpTip from 'components/atoms/HelpTip'
import Api from 'utils/Api'
import InputLabel from '@material-ui/core/InputLabel'

export default ({save, subject}) => {
  subject = subject || {}

  const publishRange = newDateTimeRange({
    startAtProps: { defaultValue: subject.start_at, required: true },
    endAtProps: { defaultValue: subject.end_at || null, required: true },
  })
  const slug = newTextField({
    default: subject.slug || "", 
    label: "Slug", fullWidth: true, disabled: true,
  })
  const title = newTextField({
    default: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const place = newTextField({
    default: subject.place || "", 
    label: "開催場所", fullWidth: true,
  })
  const ticket_url = newTextField({
    default: subject.ticket_url || "", 
    label: "チケットURL", fullWidth: true, helperText: 'peatixなどのURLを入力します'
  })
  const secret_pass = newTextField({
    default: subject.secret_pass || "", 
    label: "割引コード", fullWidth: true, helperText: '会員向け割引コード. 会員マイページに表示されます.'
  })
  const content = newHtmlEditor({
    defaultValue: subject.content || "", label: "",
    assetsProps: {baseQuery: {kind: 'event'}}
  })
  const image = newSelectableAssets({
    default: subject.images,
    max: 1,
    baseQuery: {
      kind: 'event',
      // content_type: 'image',
    }
  })

  const body = {
    start_date: publishRange.startAt.value,
    end_date: publishRange.endAt.value,
    title: title.value,
    place: place.value,
    ticket_url: ticket_url.value,
    secret_pass: secret_pass.value,
    content: content.html,
    image_ids: image.values.map(x=>x.id),
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      publishRange, slug, title, 
      place,
      ticket_url,
      secret_pass,
      content,
      image,
    ]} />
  )
}