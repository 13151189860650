import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newDateTime from 'components/molecules/forms/newDateTime'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableSearchableLabels from 'components/molecules/forms/newSelectableSearchableLabels'
import newSelectableModalList from 'components/molecules/forms/newSelectableModalList'
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

export default ({save, subject}) => {
  const classes = useStyles()
  const {handleApiError} = useFlash()
  subject = subject || {}

  const slug = newTextField({
    defaultValue: subject.slug || null, 
    label: "Slug", fullWidth: true,
    disabled: true,
  })
  const title = newTextField({
    defaultValue: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const display_order = newTextField({
    defaultValue: subject.display_order || 0, 
    label: "表示順",
    type: 'number',
  })

  const content = newHtmlEditor({
    defaultValue: subject.content || "", label: "本文html",
    assetsProps: {baseQuery: {kind: 'archive_series'}}
  })

  const image = newSelectableAssets({
    default: subject.image ? [subject.image] : [],
    max: 1,
    baseQuery: {kind: 'archive_series'}
  })


  const body = {
    slug: slug.value,
    title: title.value,
    display_order: display_order.value,
    content: content.html,
    image_id: image.values[0] && image.values[0].id,
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug, title, display_order, content, image
    ]} />
  )
}

const useStyles = makeStyles(theme=>({
  editor: {
    '&>.CodeMirror': {height: '180px'},
  }
}))
