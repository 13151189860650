import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newDateTime from 'components/molecules/forms/newDateTime'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'
import newSelectableModalList from 'components/molecules/forms/newSelectableModalList'

import ArticleListItem from 'components/pages/Articles/parts/ListItem'

export default ({save, subject}) => {
  subject = subject || {}

  const slug = newTextField({
    defaultValue: subject.slug || "", 
    label: "Slug", fullWidth: true, disabled: !!subject.id,
  })
  const title = newTextField({
    defaultValue: subject.title || "", 
    label: "タイトル", fullWidth: true, required: true,
  })
  const start_at = newDateTime({
    defaultValue: subject.start_at || "", 
    label: "刊行日時", 
    // fullWidth: true, required: true,
  })
  const cover = newSelectableModalList({
    label: "カバー",
    max: 1, defaultValue: subject.cover_article,
    modalListProps: {
      headers: ['', 'slug', 'タイトル', '公開日時', 'ステータス'],
      ListItemComponent: (props) => (<ArticleListItem {...props} />),
      onPage: ({offset, limit, updateRows}) => {
        const list = subject.articles || []
        updateRows(list, list.length)
      },
    }
  })
  const cover_caption = newTextField({
    defaultValue: subject.cover_caption || "", 
    label: "カバーキャプション", 
    fullWidth: true,
    helperText: "Ex: 4月の巻頭エッセイ",
  })

  const body = {
    slug: slug.value,
    title: title.value,
    start_at: start_at.value,
    cover_slug: cover.values[0] && cover.values[0].slug,
    cover_caption: cover_caption.value,
  }

  return (
    <GeneratedForm handleSave={()=>save(body)} objects={[
      slug, title, start_at,
      cover, cover_caption,
    ]} />
  )
}