import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';

import Icon from '@material-ui/core/Icon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import newSideMenuItem from 'components/molecules/newSideMenuItem'
import AuthToken from 'utils/AuthToken'

const useStyles = makeStyles(theme => ({
  sidemenu: {
  },
}))

export const PrimaryListItems = () => {
  const classes = useStyles()
  const path = window.location.pathname

  const userItem = newSideMenuItem({
    label: "ユーザ",
    default: !!path.match(/\/users/) || !!path.match(/\/subscriptions/) || !!path.match(/\/orders/),
    icon: "people",
    items: [
      {
        title: "会員",
        href: "/users",
        icon: "people",
      },
      {
        title: "メルマガ",
        href: "/subscriptions",
        icon: "email",
      },
      {
        title: "支払い",
        href: "/orders",
        icon: "receipt",
      },
    ]
  })
  const contentItem = newSideMenuItem({
    label: "コンテンツ",
    default: !!path.match(/\/issues/) || !!path.match(/\/authors/) || !!path.match(/\/series/) || !!path.match(/\/articles/),
    icon: "library_books",
    items: [
      {
        title: "刊行",
        href: "/issues",
        icon: "library_books",
      },
      {
        title: "著者",
        href: "/authors",
        icon: "mood",
      },
      {
        title: "シリーズ",
        href: "/series",
        icon: "clear_all",
      },
      {
        title: "記事",
        href: "/articles",
        icon: "create",
      },
    ]
  })
  const newsItem = newSideMenuItem({
    label: "ニュース",
    default: !! path.match(/\/news/),
    icon: "chat",
    items: [
      {
        title: "List",
        href: "/news",
        icon: "list",
      },
      {
        title: "New",
        href: "/news/new",
        icon: "add",
      },
    ]
  })
  const eventreportItem = newSideMenuItem({
    label: "イベントレポート",
    default: !! path.match(/\/event_reports/),
    icon: "event",
    items: [
      {
        title: "List",
        href: "/event_reports",
        icon: "list",
      },
      {
        title: "New",
        href: "/event_reports/new",
        icon: "add",
      },
    ]
  })
  const eventItem = newSideMenuItem({
    label: "イベント",
    default: !! path.match(/\/events/),
    icon: "event",
    items: [
      {
        title: "List",
        href: "/events",
        icon: "list",
      },
      {
        title: "New",
        href: "/events/new",
        icon: "add",
      },
    ]
  })
  const labelItem = newSideMenuItem({
    label: "Labels",
    default: false,
    icon: "local_offer",
    items: [
      {
        title: "List",
        href: "/labels",
        icon: "list",
      },
      {
        title: "New",
        href: "/labels/new",
        icon: "add",
      },
    ]
  })
  const componentItem = newSideMenuItem({
    label: "コンポーネント",
    defaultValue: !! window.location.pathname.match(/\/components/),
    icon: "code",
    items: [
      {
        title: '一覧',
        href: `/components`,
        icon: 'list',
      },
      {
        title: '新規作成',
        href: `/components/new`,
        icon: 'add',
      },
    ],
  })

  const archiveSeriesItem = newSideMenuItem({
    label: "アーカイブ",
    default: !! path.match(/\/archive_series/) || !! path.match(/\/archives/),
    icon: "event",
    items: [
      {
        title: 'シリーズ',
        href: `/archive_series`,
        icon: 'clear_all',
      },
      {
        title: '電子書籍',
        href: `/archives`,
        icon: 'library_books',
      },
    ],
  })

  const adminMenu = (
    <React.Fragment>
      {userItem.render}
      {/*subscriptionItem.render*/}
      {/*orderItem.render*/}
      {contentItem.render}
      {newsItem.render}
      {eventreportItem.render}
      {eventItem.render}
      {componentItem.render}
      {archiveSeriesItem.render}
    </React.Fragment>
  )

  const writerMenu = (
    <React.Fragment>
      {contentItem.render}
      {newsItem.render}
      {eventreportItem.render}
      {eventItem.render}
    </React.Fragment>
  )

  return (
    <div className={classes.sidemenu}>
      {/*<ListItem button component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>*/}

      <ListItem button component="a" href="/shared/assets">
        <ListItemIcon>
          <AttachFileIcon />
        </ListItemIcon>
        <ListItemText primary="画像・PDF" />
      </ListItem>
      
      {AuthToken.data.ability === 'admin' ? adminMenu : writerMenu}
    </div>
  )
}

export const SecondaryListItems = () => {
  // const {me} = useSuperCache()
  // const {party: {release_templates}} = me

  return (
    <div>
      {/*<ListSubheader inset>Shortcut</ListSubheader>*/}
      {/*<ListItem button component="a" href='/docs/ja/overview.html'>
        <ListItemIcon>
          <Icon>book</Icon>
        </ListItemIcon>
        <ListItemText primary="Document" />
      </ListItem>*/}
    </div>
  )
}
