import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import SendIcon from '@material-ui/icons/Send';
import RestoreIcon from '@material-ui/icons/Restore';
// import Helper from './Helper';
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

export default ({resource_key, resource, updateRowById}) => {
  const {enqueueSnackbar, handleApiError} = useFlash()

  const onPublish = () => {
    Api.fetchAuth(`/${resource_key}/${resource.id}/publish`, {method: "POST"}).then(r=>r.json()).then(res=>{
      updateRowById(res.data)
      enqueueSnackbar(res.message, {variant: 'success', persist: false})
    }).catch(handleApiError)
  }

  const onUnpublish = () => {
    Api.fetchAuth(`/${resource_key}/${resource.id}/unpublish`, {method: "POST"}).then(r=>r.json()).then(res=>{
      updateRowById(res.data)
      enqueueSnackbar(res.message, {variant: 'success', persist: false})
    }).catch(handleApiError)
  }

  return (
    <div style={{display: 'inline-block'}}>
      <Grid container alignItems="center">
        <Grid item>
          {resource.status === 'opened' && <UnpublishButton onUnpublish={onUnpublish} />}
          {resource.status === 'draft' && <PublishButton onPublish={onPublish} />}
        </Grid>
        {/*<Grid item>
          <Helper>
            <div>
              PUBLISHを実行すると、このバージョンがリリースされます。
              この処理には数分の時間がかかります。
            </div>
            <div>
              数分待っても変更が反映されない場合、ブラウザのキャッシュを確認ください。
            </div>
          </Helper>
        </Grid>*/}
      </Grid>
    </div>
  )
}

const PublishButton = ({onPublish}) => (
  <Button
    onClick={onPublish}
    variant="contained"
    color="primary"
    size="small"
    startIcon={<CachedIcon />}>
    Publish
  </Button>
)

const UnpublishButton = ({onUnpublish}) => (
  <Button
    onClick={onUnpublish}
    variant="contained"
    color="default"
    size="small"
    startIcon={<RestoreIcon />}>
    Make Draft
  </Button>
)
